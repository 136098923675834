import Footer from "../../components/shared/footer";
import React, { useEffect } from "react";
import Header from "../../components/shared/header";
import { ShowCase, Services, AboutSec } from "../../sections/sections";
import MyGallery from "../../components/gallery";
import { GrStatusGood } from "react-icons/gr";
import pipe from "../../assets/imgs/icon/pipe.png";
import helmet_2823675 from "../../assets/imgs/icon/helmet_2823675.png";
import construction from "../../assets/imgs/icon/construction.png";
import procurement from "../../assets/imgs/icon/procurement.png";

import "./home.css";
import { useNavigate } from "react-router-dom";
import Container from "../../components/container";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Landing, Landing_1 } from "../../components/LandigSlides";

const data = [
  {
    Year: "May 2007",
    des: "Stock Piling for Rivigo Jv. Ogoni - Andoni- Opobo Unity Road Project.",
    value: "N505,000,000",
    client: "Quest Maritime Ltd",
  },
  {
    Year: "July 2008",
    des: "Stock Piling for Rivigo Jv. Ogoni - Andoni- Opobo Unity Road Project.",
    value: "N180,000,000",
    client: "RIVIGO J.V.(Nig.) Ltd",
  },
  {
    Year: "March 2010- December 2010",
    des: "Stock Piling for demand of client",
    value: " N95,000,000",
    client: "CCECC, Bulletin, Julius Berger",
  },
  {
    Year: "July 2011- September 2011",
    des: "Dredging Supervision for clients at Deibu Creek (SPDC Location) well, 2, 6 and 9",
    value: "N138,000,000",
    client: "Elmadag and Basilman",
  },

  {
    Year: "January 2012",
    des: "Stock piling at Ikot Ekpene",
    value: "N16,500,000.00",
    client: "Stemco Nig. Ltd",
  },
  {
    Year: "September 2012",
    des: "Prospective sand search at Oleri, Obubu and Emadaja Communities.",
    value: " ",
    client: "Sarnar",
  },
  {
    Year: "September 2012",
    des: "Stock Pilling at Ode-Itsekiri",
    value: "N1,300,000,000",
    client: " SETRACO Nig Ltd",
  },

  {
    Year: "January 2014",
    des: "Reclamation at Iffie, Warri",
    value: "N225,000,000",
    client: " Aritse Ventures Ltd",
  },
  {
    Year: "September 2015",
    des: "Reclamation/stock pilling",
    value: "N72,000,000",
    client: "Sykes Energyprojeks Ltd",
  },
  {
    Year: "May 2018",
    des: "Land Reclamation at Abiogborodo",
    value: "N1,300,000,000",
    client: "Planet Projects Ltd",
  },
];

function Home() {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <>
      <Header title={"Mondore Limited"} />
      <div className="slide">
        <div className="slide_overlay">
          <Swiper
            slidesPerView={1}
            // onSlideChange={(e) => console.log(e)}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            loop={true}
           onSlidesUpdated={(sw)=>{
            console.log(sw.activeIndex)
           }}
            autoplay={{
              delay: 6000,
              disableOnInteraction: true,
            }}
            className="mySwiper"
          >
            <SwiperSlide onFocus={(e) => console.log(e)}>
              <Landing_1 navigate={navigate} />
            </SwiperSlide>
            <SwiperSlide>
              <Landing navigate={navigate} />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <section className="services bg-gray-50 lg:pt-20 lg:mb-10">
        <Container>
          <div className="pt-10 pb-5  flex flex-wrap m:flex-nowrap  ">
            {/* Consultation */}
            <div className="w-full   md:w-1/2 ">
              <div className="lg:w-11/12 lg:py-10 py-5 flex gap-3 ">
                <div className="icon md:w-12/12  w-10/12">
                  <img src={helmet_2823675} alt="alt" srcset="" />
                </div>
                <div className="gap-y-2">
                  {" "}
                  <h3 className="text-[#282828] font-bold text-xl uppercase">
                    Consultation
                  </h3>
                  <p className="line-clamp-3 text-start  text-[#282828] mt-1 ">
                    Leveraging the expertise of our seasoned professionals, We
                    provides consultation services to guide clients through
                    project planning, strategy development, and risk assessment.
                    Our consultants bring industry insights to optimize project
                    outcomes.
                  </p>
                </div>
              </div>
            </div>
            {/* Preseure pipping */}
            <div className="w-full   md:w-1/2 ">
              <div className="lg:w-11/12 lg:py-10 py-5 flex gap-3 ">
                <div className="icon md:w-12/12  w-10/12">
                  <img src={pipe} alt="alt" srcset="" />
                </div>
                <div className="gap-y-2">
                  {" "}
                  <h3 className="font-mont text-[#282828] font-bold text-lg  uppercase">
                    Pressure Piping System
                  </h3>
                  <p className="line-clamp-3 text-start text-[#282828] mt-1 ">
                    With a focus on precision and reliability, Mondore Ltd excel
                    in the installation, maintenance, and repair of pressure
                    pipes essential to the oil and gas infrastructure, ensuring
                    the seamless flow and transportation of resources.
                  </p>
                </div>
              </div>
            </div>

            {/* Maintainance */}
            <div className="w-full   md:w-1/2 ">
              <div className="lg:w-11/12 lg:py-10 py-5 flex gap-3">
                <div className="icon md:w-12/12  w-10/12">
                  <img src={construction} alt="alt" srcset="" />
                </div>
                <div className="gap-y-2">
                  <h3 className="text-[#282828] font-bold text-xl uppercase">
                    Maintenances
                  </h3>
                  <p className="line-clamp-3  text-[#282828] mt-1  ">
                    Recognizing the critical importance of ongoing maintenance,
                    Mondore Ltd offers comprehensive services to ensure the
                    longevity and optimal performance of oil and gas facilities.
                    Our proactive approach minimizes downtime and maximizes
                    operational efficiency.
                  </p>
                </div>
              </div>
            </div>
            {/* Procurement */}
            <div className="w-full   md:w-1/2 ">
              <div className="lg:w-11/12 lg:py-10 py-5 flex gap-3 ">
                <div className="icon md:w-12/12  w-10/12">
                  <img src={procurement} alt="alt" srcset="" />
                </div>
                <div className="gap-y-2">
                  {" "}
                  <h3 className="text-[#282828] font-bold text-xl uppercase">
                    Procurement /Logistics
                  </h3>
                  <p className="line-clamp-3  text-[#282828] mt-1 ">
                    Mondore Ltd facilitates efficient procurement and logistics
                    solutions, streamlining the supply chain for our clients.
                    Our meticulous approach ensures timely and cost-effective
                    sourcing of materials and equipment required for project
                    execution
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <AboutSec />
      <section id="bw" className=" bg-opacity-40 bg-slate-500 text-l lg:mt-16 mb-10 ">
        <div className="overlay bg-gradient-to-r from-black from-10%  font-light " >
        <Container>
          <div className="bg-full ">
            <div className="mx-auto py-24">
              <div className="md:grid grid-cols-5   lg:text-start  items-center space-y-8 md:space-y-0">
                <div className="col-span-3">
                  <p className=" text-gray-100 lg:px-20 py-10 text-3xl text-center lg:text-start">
                    Our commitment to superior solutions, innovation, and
                    compliance with international standards sets us apart.
                    <br />
                    Discover excellence with Us.
                  </p>
                </div>
                <div className="col-span-2 flex justify-center items-center ">
                  <button
                    onClick={() => navigate("/contact-us")}
                    className="py-5 px-10 text-white hover:bg-gray-600 hover:text-white bg-warning rounded-sm text-lg  font-semibold shadow-sm"
                  >
                    Contact Us Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        </div>
        
      </section>
      {/* table */}
      <section className="pt-20 pb-10 ">
        <Container>
          <div className="overview lg:pb-6 ">
            <div className="flex flex-col  gap-y-5 ">
              <h4 className=" uppercase font-bold text-4xl text-[#282828]  w-8/12">
                Our Job Experience
              </h4>
              <p className="lg:w-3/5 ">
                With an exceptional engineering and related services to the
                Nigerian Oil and Gas industry. Mondore Ltd, established in 2020,
                has rapidly emerged as a leader in the field, focusing on
                dredging, pressure pipes works, procurement/logistics,
                engineering constructions and maintenances.
              </p>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full text-left overflow-x-auto">
                      <thead className="border-b-2 bg-gray-100 font-medium">
                        <tr>
                          <th scope="col-span-2" className="px-6 py-4">
                            Project Description/location with date
                          </th>
                          <th scope="col" className="px-6 py-4">
                            Client
                          </th>
                          <th scope="col" className="px-6 py-4">
                            Status
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((row, index) => (
                          <tr key={index} className="border-b border-gray-200">
                            <td className="px-6 py-4 col text-left whitespace-break-spaces ">
                              {row.des} <br />
                              <span className="text-sm text-gray-500">
                                {row.Year}
                              </span>
                            </td>
                            <td className="px-6 py-4 col whitespace-nowrap flex items-center ">
                              {row.client}
                            </td>

                            <td className="px-6 py-4 col whitespace-nowrap items-center  self-center justify-center">
                              <GrStatusGood
                                color="green"
                                strokeWidth={1}
                                size={20}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <MyGallery />
      <ShowCase navigate={navigate} />

      <Footer />
    </>
  );
}

export default Home;
