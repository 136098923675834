import street from "../assets/imgs/icon//street-map.png";
import contactIcon from "../assets/imgs/icon/contact-us.png";
import site_info from "../assets/profile";
import Container from "../components/container";
import Logo from "../components/shared/logo";

import "animate.css";

const ContactSec = () => (
  <section className=" overflow-x-hidden ">
    <Container>
      {" "}
      <div className="pt-20 pb-8">
        <p className="text-gray-700 sm:text-xl animate__animated animate__bounce lg:w-7/12">
          Have questions or need more information?
          <br /> Connect with our team today. We are here to discuss your
          project requirements, offer insights, and provide solutions that align
          with your goals.
        </p>
      </div>
    </Container>
    <div className="bg-gray-50">
      <Container>
        <div className="py-8 space-y-10 lg:space-y-0 lg:py-16   lg:flex mx-auto gap-8">
          <form
            action="#"
            className="lg:order-1 space-y-3 lg:w-6/12 border lg:px-10 px-4 py-10 rounded-lg border-slate-400 "
            data-aos="fade-left"
          >
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Your Name
              </label>
              <input
                type="text"
                id="name"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                placeholder="Name"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Your email
              </label>
              <input
                type="email"
                id="email"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                placeholder="name@email.com"
                required
              />
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
                placeholder="Let us know how we can help you"
                required
              />
            </div>
            <div className="sm:col-span-2 ">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                Your message
              </label>
              <textarea
                id="message"
                rows="6"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                placeholder="Leave a message..."
              ></textarea>
            </div>
           <div className=" flex justify-end  pt-5">
           <button
              type="submit"
              disabled
              className="py-3 px-5   text-sm font-medium text-center text-slate-50 rounded-lg bg-blue-700 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-primary-300 "
            >
              Send message
            </button>
           </div>
          </form>
          <div className="lg:w-6/12 py-10 rounded" data-aos="fade-right">
            <div className="contact  mb-10">
              <div className="icon mx-auto flex  items-baseline">
                <Logo
                  name={"Mondore Limited"}
                  title_class={"font-bold text-2xl text-primary"}
                />
              </div>
              <p className="mt-3 ">
                To be renowned in the provision of technical and non-technical
                solutions to our esteemed clients with assurances driven by
                quality, integrity and safety.
              </p>
            </div>

            <div className="contact flex gap-2 mb-5">
              <div className="icon">
                <img src={contactIcon} alt="" width={"60px"} srcSet="" />
              </div>
              <p>
                {site_info.phone} <br />
                {site_info.email}
              </p>
            </div>
            <div className="contact flex gap-2 mb-5">
              <div className="icon">
                <img src={street} alt="" width={"70px"} srcSet="" />
              </div>
              <p className="">{site_info.address} </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </section>
);

export default ContactSec;
