import React, { useEffect,  } from "react";
import Header from "../components/shared/header";
import PageHero from "./page_hero";
import ContactSec from "../sections/contact";
import Footer from "../components/shared/footer";
import "./contact.css";

import { MyMap } from "../components/map";





function Contact() {
  

 

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      return null;
    };
  }, []);


  return (
    <div>
      <Header title={"Mondore | contact"} />
      <div className="contact_hero">
        <div className="contact_overlay">
          <PageHero page_title={"Contact"} myClass={"text-white"} />
        </div>
      </div>
      <ContactSec />
      <MyMap/>

      <Footer />
    </div>
  );
}

export default Contact;
