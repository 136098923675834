import React from 'react'
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original:
      "https://res.cloudinary.com/dozd0nyze/image/upload/e_improve:outdoor/c_scale,h_600,w_1000/mondorelimited/ofj2qpqu5y8ntybrotgh.jpg",
    thumbnail:
      "https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_150,w_250/mondorelimited/ofj2qpqu5y8ntybrotgh.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dozd0nyze/image/upload/e_improve:outdoor/c_scale,h_600,w_1000/mondorelimited/vvi3xggigenmv9sh6fby.jpg",
    thumbnail:
      "https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_150,w_250/mondorelimited/vvi3xggigenmv9sh6fby.jpg",
  },
  {
    original:
      "https://res.cloudinary.com/dozd0nyze/image/upload/e_improve:outdoor/c_thumb,h_600,w_1000/mondorelimited/dlsvo80np5hrtb7ougba.jpg",
    thumbnail:
      "https://res.cloudinary.com/dozd0nyze/image/upload/e_improve:outdoor/c_thumb,h_150,w_250/mondorelimited/dlsvo80np5hrtb7ougba.jpg",
  },
];

class MyGallery extends React.Component {
  render() {
    return (
      <ImageGallery
        items={images}
        autoPlay={true}
        showFullscreenButton={false}
        showNav={false}
        showPlayButton={false}
      />
    );
  }
}

export default MyGallery