import Header from "../components/shared/header";
import PageHero from "./page_hero";
import AboutSec from "../sections/about_m";
import Footer from "../components/shared/footer";
import BlenderSVG from "../components/blenderSVG";
import about_m from "../assets/imgs/dredgin.png";
import Team from "../sections/team";
import site_info, { keyServices } from "../assets/profile";

const About = () => {
 
  return (
    <>
      <Header title={"Mondore | About Us"} />
      <PageHero page_title={"About Us"} myClass={" bg-gray-600"} />
      <BlenderSVG bg="bg-gray-600" fill="ffff" />
      <AboutSec />

      <div className="w-full">
        <div className="top_part lg:py-36 py-20 bg-gray-600"></div>

        <div className="lg:grid  lg:bg-white lg:grid-cols-4 grid-flow-dense gap-5  pb-10 max-w-screen-lg mx-auto  lg:-mt-44 -mt-16 ">
          <div className="images lg:mx-0  mx-3 row-span-2 col-span-2 h-3/4">
            <img src={about_m} alt="" srcSet="" />
          </div>

          <div className="heading col-span-2">
            <h3 className="text-2xl font-bold uppercase text-indigo-700 font-serif first-letter:text-5xl p-5 text-center">
              Our Work Ethics and Philosohy
            </h3>
            <p className="text-lg ml-5 pb-5 first-letter:font-semibold first-letter:text-5xl">
              At Mondore Ltd, we believe that the foundation of a successful
              business lies in the principles that guide our actions, decisions,
              and interactions. Our work ethics are not just a set of rules;
              they are the heartbeat of our organization, shaping the way we
              operate and delivering exceptional value to our clients and
              partners.
            </p>
          </div>
          {keyServices.map((item, ind) => (
            <div key={ind} className="content col-span-2 mb-5 px-5">
              <div className="">
                <p className="title font-bold uppercase">{item.title}:</p>
                <p className=" "> {item.body} </p>
              </div>
            </div>
          ))}
        </div>
        <Team team={site_info.directors} />
      </div>

      <Footer />
    </>
  );
};

export default About;
