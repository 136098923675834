import React from 'react';
import Home from "./pages/home/home";
import Login from "./pages/auth/login";
import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './pages/404';
import Register from './pages/auth/register';
import About from './pages/about';
import Service from './pages/service';
import Contact from './pages/contact';

export const router = createBrowserRouter([
  { path: "/about", element: <About   />, },
  { path: "/contact-us", element: <Contact />, },
  { path: "/services", element: <Service />, },
 
 

  { path:  "/", element: <Home />, },
  { path: "*", element: <ErrorPage /> }
]);
