export const keyServices = [
  {
    title: "Commitment to Excellence",
    body: "At Mondore Ltd, excellence is not a goal; it is a standard. We are committed to delivering services of the highest quality. Every project we undertake is approached with a passion for excellence, ensuring that we not only meet but surpass industry standards",
  },
  {
    title: "Client-Centric Focus",
    body: "Our Client's success is our success. Our clients are at the center of everything we do. Mondore Ltd is dedicated to understanding their unique needs, providing tailored solutions, and fostering enduring partnerships that stand the test of time.",
  },
  {
    title: "Integrity and Transparency",
    body: "Honesty breeds trust. Mondore Ltd operates with the utmost integrity and transparency. We believe in clear communication, maintaining trust and accountability with our clients, partners, and team members.",
  },
  {
    title: "Innovation and Adaptability",
    body: "Mondore Ltd Embraces changes, foster innovation. In a rapidly evolving industry, Mondore Ltd thrives on innovation. We encourage a culture of creativity and adaptability, embracing new ideas and technologies to address challenges and stay ahead of industry trends.",
  },
  {
    title: "Environmental Stewardship",
    body: "Sustainability is our responsibility. Mondore Ltd acknowledges the importance of environmental stewardship. We integrate sustainable practices into our operations, minimizing our ecological footprint and contributing to environmental conservation.",
  },
];



const site_info = {
  name: "Mondore Limited",
  address: "No.2 Oyo Street, Area 2, Garki, Amac, FCT, Abuja.",
  phone: "+234 (0) 813 209 2763",
  email: "info@mondorelimited.com",
  directors: [
    {
      name: "Iheanyi O. Onyeukwu",
      position: "managing director",
      imgUrl:
        "https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_800,w_800/mondorelimited/q9fngqrxqhm2pzr0zvae.jpg",
      qualification: "",
      twitter: "",
      linkedin: "",
      insta: "",
    },
    {
      name: "Raymond s. Isokariari",
      email: "raymond@mondorelimited.com",
      position: "Director",
      imgUrl:
        "https://res.cloudinary.com/dozd0nyze/image/upload/c_crop,g_auto,h_1000,w_1000/mondorelimited/IMG_07732_kwjko7",
      qualification: "",
      twitter: "",
      linkedin: "",
      insta: "",
    },
    {
      name: "Edgar c. Osumili",
      position: "director",
      imgUrl: "",
      qualification: "",
      twitter: "",
      linkedin: "",
      insta: "",
    },
    {
      name: "MANN-DOKUBO, PRECIOUS BOMA",
      position: "Project director",
      imgUrl: "",
      qualification: "",
      twitter: "",
      linkedin: "",
      insta: "",
    },
  ],
};
export default site_info;
