import { Link } from "react-router-dom";
import crane1 from "../assets/imgs/dregin2.png";
import crane2 from "../assets/imgs/dredgine.png"
import ray1 from "../assets/imgs/ray1.png";
import ray2 from "../assets/imgs/ray2.png";
import ray6 from "../assets/imgs/piping.jpg";
import Container from "../components/container";

const ShowCase = ({ navigate }) => (
  <section className="bg-gray-50 my-10 ">
    <Container>
    <div className="gap-16 items-center py-8 lg:grid lg:grid-cols-2 lg:py-16">
      <div className=" text-gray-600 sm:text-lg mb-20">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-500">
          Our Expertise
        </h2>
        <p className="mb-4">
          Explore our diverse range of services encompassing civil construction,
          consultation services, maritime infrastructural development,
          maintenance, and installations. Our team of seasoned professionals
          ensures precision and excellence in every project we undertake.
        </p>

        <div className="btn mt-8">
          <button
            onClick={() => navigate("/contact-us")}
            className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            Speak With an Expert
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-8">
        <img
          data-aos="fade-down-right"
          className="mt-4 w-full rounded-lg"
          src={crane2}
          alt="office content "
        />
        <img
          data-aos="fade-down-left"
          className="w-full rounded-lg"
          src={crane1}
          alt="office content 1"
        />
        <img
          data-aos="fade-up-left"
          className="w-full rounded-lg"
          src={ray2}
          alt="office content 1"
        />
        <img
          data-aos="fade-up-right"
          className="mt-4 w-full rounded-lg"
          src={ray1}
          alt="office content 2"
        />
      </div>
    </div>

    <div className="gap-8 items-start py-2  mx-auto mt-9  xl:gap-16 md:grid md:grid-cols-2 sm:py-16 ">
      <div data-aos="zoom-in-right" data-aos-delay="150" className="images">
        <img className="w-full shadow-md" src={ray6} alt="dashboard imge" />
        
      </div>
      <div data-aos="fade-left" data-aos-delay="150" className="mt-4 md:mt-0">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900" >
          Installation of Pressure Pipe Work Systems
        </h2>
        <p className="mb-6 text-gray-800 md:text-lg ">
          We specializes in the precise installation of pressure pipe work
          systems. Our team of skilled engineers ensures that every project is
          executed with the highest standards of safety and efficiency. We
          handle a wide range of materials and specifications, providing
          customized solutions for various industries.
        </p>
        <p className="mb-6 text-gray-800 md:text-lg">
          Discover our legacy of innovation and commitment to delivering
          projects of unparalleled quality.
        </p>
        <div className="justify-center lg:justify-start flex pb-12">
          <Link
            to="/services"
            className="inline-flex items-center text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            Get started
            <svg
              className="ml-2 -mr-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
    </Container>
  </section>
);

export default ShowCase;
