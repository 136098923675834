import { Link } from "react-router-dom";

const PageHero = ({ page_title, myClass }) => {
  return (
    <div className={`w-full ${myClass}`}>
      <div className="max-w-screen-md mx-auto lg:py-20 py-10 justify-center flex flex-col font-bold">
        <h1 data-aos="fade-down" className="text-5xl  text-center">
          {page_title}
        </h1>
        <div data-aos="fade-up" className="bredc flex justify-center mt-5">
          <span className="breadcrums text-center text-lg m-0 p-0">
            <Link to={"/"}>Home </Link>
          </span>
          &nbsp;{" "}
          <span className="self-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3.5}
              stroke="currentColor"
              className="w-4 h-4 mb-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>{" "}
          &nbsp;
          <span className="self- text-lg">{page_title}</span>
        </div>
      </div>
    </div>
  );
};

export default PageHero;
