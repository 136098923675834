import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { useEffect } from 'react'
import AOS from"aos"
import "aos/dist/aos.css"


function App() {
useEffect(()=>{
  AOS.init()
  return ()=>{
    
  }
},[])
    
  return (
    <div className="app overflow-x-hidden">
    <RouterProvider router={router} />
    </div> 
  )
}

export default App