import { useNavigate } from "react-router-dom";
import ray2 from "../assets/imgs/dredgine.png";
import ray5 from "../assets/imgs/dredger6.jpg";
import site_info from "../assets/profile";

import { useEffect } from "react";
import Container from "../components/container";

const AboutSec = () => {
  const navigate = useNavigate();

  useEffect(()=>{
  },[])
  return (
    <section className="about_mondore overflow-x-hidden">
      <Container>
      <div className="lg:grid grid-cols-2 py-20 mx-auto  gap-10">
      <div
        data-aos="fade-right"
        className="images  mx-auto justify-center items-center flex flex-col lg:block  ">
         <img src={ray5} alt="" srcset="" />
         
        </div>
        <div
        data-aos="fade-left"
        className="s  mx-auto  text-gray-500 sm:text-lg ">
          <h4 className="">
            About &nbsp;{" "}
            <span className="text-lg font-bold underline-offset-8 underline text-indigo-600 ">
              {site_info.name}
            </span>
          </h4>
          <h2 className="text-4xl font-semibold mt-2 break-words">
            Dredging, Pressure Pipes Works, Procurement/Logistics, Engineering
            Constructions and Maintenance
          </h2>
          <p className="  mt-10">
            Mondore Ltd, established in 2020, is a dynamic company dedicated to
            providing comprehensive engineering and related services to the
            Nigerian Oil and Gas industry. Our commitment is rooted in healthy
            integrations and technical alliances, leveraging the expertise of
            our experienced personnel to deliver superior services that adhere
            to both local and international standards.
          </p>
          {/* <div className="justify-center flex lg:block my-10">
            <button
              onClick={() => navigate("/services")}
              className="inline-flex items-center text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Learn More
              <svg
                className="ml-2 -mr-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div> */}
        </div>
       
      </div>  

      </Container>
     
    </section>
  );
};

export default AboutSec;
