import { Link } from "react-router-dom";




export const LogoIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.89 51.6" {...props}>
      <defs>
        <style>{".cls-1{fill:#fdd218;}.cls-2{fill:#262261;}"}</style>
      </defs>
      <title>{"Asset 2"}</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-1"
            d="M14.81,14.52a5,5,0,0,1,.08,1,17.1,17.1,0,0,1-.22,2.82c-.09.67-.23,1.4-.41,2.18A6.27,6.27,0,0,1,9,25.07l-.77-1.32a3.61,3.61,0,0,0-.41-4.37A6.26,6.26,0,0,1,6,15.94c-2.28,3.84-1.1,6,0,7.82l-.8,1.31c-3.16-.7-5.39-4-5.23-7.8,0,0-.31-3.72,3.33-7.35,0,0,3.34-3.64,3-6.79a3.94,3.94,0,0,0-.24-.94l0-.12c0-.29-.79-1.84-.61-2S6.8.91,7,1l.08,0a11.2,11.2,0,0,1,3.42,5.23,7.7,7.7,0,0,1-.06,3.23c-.16,1-1.32,2.06-1.49,3a5.12,5.12,0,0,0,.08,2,6.1,6.1,0,0,0,.49,1.07,1.81,1.81,0,0,0,1.37.69,1.27,1.27,0,0,0,1-.39,5.65,5.65,0,0,0,.7-.75A5.11,5.11,0,0,0,13,13.94l.36-1.79c.67-3.25.81-3.05.81-3.05"
          />
          <polygon
            className="cls-2"
            points="9.26 51.6 5.93 51.6 3.95 32.6 0.62 26.41 14.28 26.41 11.05 32.5 9.26 51.6"
          />
        </g>
      </g>
    </svg>
  );



const Logo = ({ name, title_class, icon_height = "45px", visibility }) => (
  <Link to={"/"} className={`flex items-baseline ${visibility}`}>
    <LogoIcon height={icon_height} />

    <p
      className={` ${title_class} font-sans  whitespace-wrap`}
    >
      {name}
    </p>
  </Link>
);

export default Logo;