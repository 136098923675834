import Container from "./container";

export const Landing = ({ navigate }) => {
  return (
    <div className="hero  " id="hero">
        <div className="over bg-gradient-to-t via-90%  from-10% via-transparent from-primary">

      <Container>
        <div className=" py-24 justify-center">
          <h3
            data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="500"
            className="text-white leading-tight px-5 font-semibold text-5xl text-center lg:text-start lg:text-7xl lg:w-8/12  "
          >
            We've Got the pressure under control
          </h3>
          <div
            data-aos="fade-up"
            data-aos-offset="500"
            data-aos-anchor="#example-anchor"
            data-aos-delay="500"
            className="btns mt-14 flex px-5 gap-7 justify-center lg:justify-start  lg:w-8/12 "
          >
            <button
              onClick={() => navigate("/services")}
              className="text-white bg-transparent py-5 px-10 border-2 border-warning  focus:outline-warning hover:bg-warning  font-semibold rounded-sm hover:text-primary"
            >
              Explore More...
            </button>
          </div>
        </div>
      </Container>
    </div>
    </div>

  );
};
export const Landing_1 = ({ navigate }) => {
  return (
    <div className="hero1  " id="hero1">
      <div className="overlay bg-gradient-to-r from-primary from-10%  ">
        <Container>
          <div className=" py-24 justify-center">
            <h3
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
              className="text-white  px-5 font-semibold text-5xl text-center lg:text-start lg:text-7xl lg:w-8/12 "
            >
              Transforming Waterways with Precision Dredging
            </h3>
            <div
              data-aos="fade-up"
              data-aos-offset="500"
              data-aos-anchor="#example-anchor"
              data-aos-delay="500"
              className="btns mt-14 flex px-5 gap-7 justify-center lg:justify-start  lg:w-8/12 "
            >
              <button
                onClick={() => navigate("/services")}
                className="inline-flex text-white bg-warning border-0 py-5 px-10 focus:outline-none hover:bg-opacity-80  font-semibold"
              >
                Explore More...
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
